export function spanMethod({ row, columnIndex }, arr = []) {
  if (!arr.includes(columnIndex)) {
    return {
      rowspan: row.span === 0 ? 0 : row.span || 1,
      colspan: 1,
    };
  } else {
    return {
      rowspan: 1,
      colspan: 1,
    };
  }
}

export function changeDataList(
  list,
  childKey = "shop_trade_sell_dos",
  keyList = ["shop_name", "card_num"]
) {
  return [].map
    .call(list, (item) => {
      const childList = item[childKey] || [];
      let len = childList.length;
      item.span = 1;
      if (len <= 0) return item;
      if (len === 1) {
        keyList.forEach((key) => {
          if(typeof childList[0] === "object") {
            item[key] = childList[0][key] || item[key];
          }
          else item[key] = childList[0];
        });
        return item;
      } else {
        let sign = false;
        let items = childList.map((d, i) => {
          let _item = { ...item };
          keyList.forEach((key) => {
            if(typeof d === "object") {
              _item[key] = d[key];
            }
            else _item[key] = d;
          });
          if (!sign) {
            _item.span = len;
            sign = true;
          } else {
            _item.span = 0;
            _item.id += "_" + i;
          }
          return _item;
        });
        return items;
      }
    })
    .flat();
}


export function groupArrayByKey(arr = [], key) {
  return arr.reduce(
    (t, v) => (!t[v[key]] && (t[v[key]] = []), t[v[key]].push(v), t),
    {}
  );
}

export function createShopCardList(res) {
  return Object.values(groupArrayByKey(res, "shop_id"))
    .map((list) => {
      let cds = [];
      list.forEach((item, index) => {
        item.span = [1, 1];
        if (index === 0) item.span[0] = list.length;
        else item.span[0] = 0;
        if (!cds.includes(item.card_id)) {
          cds.push(item.card_id);
          item.span[1] = list.filter((d) => d.card_id == item.card_id).length;
        } else {
          item.span[1] = 0;
        }
      });
      return list;
    })
    .flat();
}
