<template>
  <div class="sale-card-page">
    <en-table-layout :toolbar-two="true" :table-data="tableData.data" :span-method="spanMethod" ref="enTableLayout"
      @selection-change="handleSelectionChange" :row-key="getRowKeys">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="销售关卡核销" name="saleClose"></el-tab-pane>
          <el-tab-pane label="销售开卡核销" name="saleOpen"></el-tab-pane>
        </el-tabs>
      </template>
      <template slot="toolbar">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="searchForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="item in filterdouble" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="单据状态" class="col-auto" v-if="activeName === 'saleClose'">
          <el-select style="width: 140px" v-model="searchForm.close_card_state" size="medium" clearable>
            <el-option v-for="item in cardCloseStatusType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="单据状态" class="col-auto" v-if="activeName === 'saleOpen'">
          <el-select style="width: 140px" v-model="searchForm.close_card_state" size="medium" clearable>
            <el-option v-for="item in cardOpenStatusType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="卡号区间" class="col-auto" :error="cardRangeError">
          <el-input style="width: 140px" v-model.trim="searchForm.card_code_start" clearable @blur="handleCardRange" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 140px" v-model.trim="searchForm.card_code_end" clearable @blur="handleCardRange" />
        </el-form-item>

        <el-form-item label="单据日期" class="col-auto">
          <el-date-picker style="width: 220px" v-model="timeRange" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
      </template>
      <template slot="toolbar-two">
        <el-form-item label="关卡类型" class="col-auto" v-if="activeName === 'saleClose'">
          <el-select style="width: 140px" v-model="searchForm.oper_type" size="medium" clearable>
            <el-option v-for="item in cardCloseType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="开卡类型" class="col-auto" v-if="activeName === 'saleOpen'">
          <el-select style="width: 140px" v-model="searchForm.oper_type" size="medium" clearable>
            <el-option v-for="item in cardOpenType" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item class="col-auto">
          <el-input style="width: 360px" v-model.trim="keywords" placeholder="请输入关键词" clearable>
            <el-select style="width: 130px" v-model="search_type" slot="prepend">
              <el-option label="销售关卡单号" value="close_sn" v-if="activeName === 'saleClose'"></el-option>
              <el-option label="销售开卡单号" value="open_sn" v-if="activeName === 'saleOpen'"></el-option>
              <el-option label="关联销售单号" value="sell_refund_record_sn" v-if="activeName === 'saleClose'"></el-option>
              <el-option label="关联销售关卡单号" value="close_sn" v-if="activeName === 'saleOpen'"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="制单人名称" value="make_order_name"></el-option>
              <el-option label="备注信息" value="remark"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('search')">搜索</el-button>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 0)">导出全部</el-button>
        <el-button size="small" type="primary" @click="saleCardRefreshPage('exportCardList', 1)">导出勾选项</el-button>
      </template>
      <!-- 关卡 -->
      <template slot="table-columns" v-if="activeName === 'saleClose'">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column label="销售关卡单号" width="160" prop="sn" fixed />
        <el-table-column label="关联销售单号" width="160" prop="related_sn" fixed />
        <el-table-column label="应用类型" width="110">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column label="关卡类型" width="110">
          <template slot-scope="{ row }">
            <!-- 整单关卡/部分关卡 -->
            {{ ["", "部分关卡", "整单关卡"][row.oper_type] }}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="client_name" width="120" show-overflow-tooltip />
        <el-table-column label="需关卡数量" prop="card_num" width="110" />
        <el-table-column label="实关卡数量" prop="real_card_num" width="110" />
        <el-table-column label="单据状态">
          <template slot-scope="{ row }">
            {{ getCardCloseStatusTypeName(row.close_card_status) }}
          </template>
        </el-table-column>
        <el-table-column label="制单人" prop="make_order_name" show-overflow-tooltip />
        <el-table-column label="单据日期" prop="create_time" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remark" width="180" show-overflow-tooltip />
        <el-table-column label="操作" align="center" fixed="right" width="180">
          <template slot-scope="scope">
            <!-- <el-dropdown
              @command="getDetail(1, scope.row, $event)"
              style="margin-right: 10px"
            >
              <el-button type="primary" size="mini">查看</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="part">部分关卡类型</el-dropdown-item>
                <el-dropdown-item command="all">整单关卡类型</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <el-button type="primary" size="mini" @click="
      getDetail(
        1,
        scope.row,
        ['', 'part', 'all'][scope.row.oper_type]
      )
      ">查看</el-button>
            <el-button type="primary" v-if="scope.row.close_card_status == 0" size="mini"
              @click="handleCloseCard(scope.row)">关卡</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 关卡 -->

      <!-- 开卡 -->
      <template slot="table-columns" v-if="activeName === 'saleOpen'">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column label="销售开卡单号" width="160" prop="sn" fixed />
        <el-table-column label="关联销售关卡单号" width="160" prop="close_sn" fixed />
        <el-table-column label="应用类型" width="110">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column label="开卡类型" width="110">
          <template slot-scope="{ row }">
            <!-- 整单开卡/部分开卡 -->
            {{ ["", "部分开卡", "整单开卡"][row.oper_type] }}
          </template>
        </el-table-column>
        <el-table-column label="客户名称" prop="client_name" width="120" show-overflow-tooltip />
        <el-table-column label="需开卡数量" prop="card_num" width="110" />
        <el-table-column label="已开卡数量" prop="real_card_num" width="110" />
        <el-table-column label="单据状态">
          <template slot-scope="{ row }">
            {{ getCardOpenStatusTypeName(row.close_card_status) }}
          </template>
        </el-table-column>
        <el-table-column label="制单人" prop="make_order_name" show-overflow-tooltip />
        <el-table-column label="单据日期" prop="create_time" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <el-table-column label="备注信息" prop="remark" width="180" show-overflow-tooltip />
        <el-table-column label="操作" align="center" fixed="right" width="180">
          <template slot-scope="scope">
            <!-- <el-dropdown
              @command="getDetail(1, scope.row, $event)"
              style="margin-right: 10px"
            >
              <el-button type="primary" size="mini">查看</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="part">部分开卡类型</el-dropdown-item>
                <el-dropdown-item command="all">整单开卡类型</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <el-button type="primary" size="mini" @click="
      getDetail(
        1,
        scope.row,
        ['', 'part', 'all'][scope.row.oper_type]
      )
      ">查看</el-button>
            <el-button type="primary" v-if="scope.row.close_card_status == 0" size="mini"
              @click="handleOpenCard(scope.row)">开卡</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 开卡 -->

      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-size="tableData.page_size" :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout" background :total="tableData.data_total" />
    </en-table-layout>
  </div>
</template>

<script>
import * as API_SaleOrder from "@/api/saleCard";
import * as API_Sale from "@/api/saleCard";
import { Foundation } from "@/../ui-utils";
import * as API_order from "@/api/order";
import { handleDownload, downloadExcel } from "@/utils";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import {
  changeDataList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";

export default {
  name: "closeCardWrite",
  mixins: [mixins],
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      activeName: "saleClose",
      timeRange: "",
      keywords: "",
      search_type: "close_sn",
      searchForm: {
        close_card_state: "",
        card_code_start: "",
        card_code_end: "",
        start_time: "",
        end_time: "",
        app_type_shop_type_flag: "",
        keywords: "",
        status: "",
        oper_type: "",
      },
      cardRangeError: "",
    };
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  computed: {
    filterdouble () {
      if (!this.double_choice_mall_open) {
        this.cardTypes.splice(2, 1)
      }
      return this.cardTypes;
    }
  },
  methods: {
    spanMethod (opt) {
      switch (this.activeName) {
        case "saleClose":
          return spanMethod(opt, [2]);
        case "saleOpen":
          return spanMethod(opt, [2]);
        default:
          break;
      }
    },
    // 卡号区间校验
    handleCardRange () {
      /** 判断输入的卡号是否包含前缀 **/
      const cardReg = /^[a-zA-Z]+\d{3,}$|^\d{3,}$/;
      const letterReg = /^[a-zA-Z]+\d+$/;
      if (this.searchForm.card_code_start && this.searchForm.card_code_end) {
        if (
          cardReg.test(this.searchForm.card_code_start) &&
          cardReg.test(this.searchForm.card_code_end)
        ) {
          // 包含前缀
          if (
            letterReg.test(this.searchForm.card_code_start) &&
            letterReg.test(this.searchForm.card_code_end)
          ) {
            const startPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_start
            )[0];
            const endPrefix = /[a-zA-Z]+/.exec(
              this.searchForm.card_code_end
            )[0];
            if (startPrefix !== endPrefix) {
              this.cardRangeError = "当前卡号区间格式不一致。";
            } else if (startPrefix.length > 5 || endPrefix.length > 5) {
              this.cardRangeError =
                "当前卡号区间格式不正确，请重新填写卡号区间。";
            } else {
              // 判断数组部分大于3位是否以0开头
              // const startNum = /\d+/.exec(this.searchForm.card_code_start)[0];
              // const endNum = /\d+/.exec(this.searchForm.card_code_end)[0];
              // if (/^0\d{3,}/.test(startNum) || /^0\d{3,}/.test(endNum)) {
              //   this.cardRangeError =
              //     "当前卡号区间格式不正确，请重新填写卡号区间。";
              // } else {
              this.cardRangeError = "";
              // }
            }
          } else if (
            (letterReg.test(this.searchForm.card_code_start) &&
              !letterReg.test(this.searchForm.card_code_end)) ||
            (!letterReg.test(this.searchForm.card_code_start) &&
              letterReg.test(this.searchForm.card_code_end))
          ) {
            this.cardRangeError = "当前卡号区间格式不一致。";
          } else {
            if (
              parseInt(this.searchForm.card_code_end) <
              parseInt(this.searchForm.card_code_start)
            ) {
              this.cardRangeError =
                "结束框卡号数字部分不能小于起始输入框卡号数字部分。";
            } else if (
              /^0\d{3,}/.test(this.searchForm.card_code_end) ||
              /^0\d{3,}/.test(this.searchForm.card_code_start)
            ) {
              // 满足3位以上数字且以0开头
              // this.cardRangeError =
              //   "当前卡号区间格式不正确，请重新填写卡号区间。";
              this.cardRangeError = ""
            } else {
              this.cardRangeError = "";
            }
          }
        } else {
          this.cardRangeError = "当前卡号区间格式不正确，请重新填写卡号区间。";
        }
      } else if (
        !this.searchForm.card_code_start &&
        !this.searchForm.card_code_end
      ) {
        this.cardRangeError = "";
      } else {
        this.cardRangeError = "请输入正确卡号区间进行搜索";
      }
    },
    clearSearchForm () {
      this.searchForm = {
        card_code_start: "",
        card_code_end: "",
        start_time: "",
        end_time: "",
        app_type_shop_type_flag: "",
        keywords: "",
        status: "",
        oper_type: "",
        close_card_state: "",
      };
      this.timeRange = "";
      this.multipleSelection = [];
      this.$refs.enTableLayout.clearSelected();
    },
    handleClick (e) {
      this.keywords = "";
      this.search_type =
        this.activeName == "saleClose" ? "close_sn" : "open_sn";
      this.params = {
        page_no: 1,
        page_size: 20,
      };
      this.clearSearchForm();
      this.tableData.data = [];
      this.getList();
    },
    search () {
      if (this.cardRangeError) {
        this.$message.error("请先输入正确的卡号区间再搜索");
        return;
      }
      if (
        this.searchForm.card_code_start &&
        this.searchForm.card_code_end &&
        (this.searchForm.status ||
          this.searchForm.keywords ||
          this.order_time_range ||
          this.searchForm.app_type_shop_type_flag ||
          this.oper_type ||
          this.close_card_state)
      ) {
        this.$message.error("卡号区间搜索不能和其他筛选条件同时使用");
        return;
      }
      this.params = {
        page_no: this.params.page_no,
        page_size: this.params.page_size,
        ...this.searchForm,
      };
      if (this.timeRange) {
        this.params["start_time"] = this.timeRange[0].getTime() / 1000;
        this.params["end_time"] = this.timeRange[1].getTime() / 1000 + 86399;
      }
      if (this.keywords) {
        this.params[this.search_type] = this.keywords;
      } else {
        delete this.params[this.search_type];
      }
      if (!this.searchForm.card_code_start || !this.searchForm.card_code_end) {
        delete this.params["card_code_start"];
        delete this.params["card_code_end"];
      }
      // this.multipleSelection = []
      // this.$refs.enTableLayout.clearSelected();
      this.getList();
    },
    getList () {
      const API =
        API_SaleOrder[
        this.activeName == "saleClose"
          ? "getCloseCardList"
          : "getCloseOpenCardList"
        ];
      const params = {
        ...this.params
      }
      API({
        ...params
      }).then((res) => {
        this.tableData = res;
        this.tableData.data = changeDataList(
          res.data,
          "close_sell_trade_sn_list",
          ["related_sn"]
        );
        this.$nextTick(() => {
          this.$refs.enTableLayout.$refs.table.doLayout();
        });
      });
    },
    // 导出
    exportCardList (type) {
      // 0 导出全部 1 导出选中
      let params = {
        ...this.params,
      };
      params.page_no = 1;
      params.page_size = 999999;
      if (type === 1) {
        if (!this.multipleSelection.length) {
          this.$message.error("请先选择要导出的数据");
          return false;
        }
        params["ids"] = this.multipleSelection.map((item) => {
          return item.id;
        });
      }
      let tableData;
      if (this.activeName === "saleClose") {
        tableData = [
          ["sn", "销售关卡单号"],
          ["related_sn", "关联销售单号"],
          ["app_type_shop_type_flag", "应用类型"],
          ["oper_type", "关卡类型"],
          ["client_name", "客户名称"],
          ["card_num", "需关卡数量"],
          ["real_card_num", "实关卡数量"],
          ["close_card_status", "单据状态"],
          ["make_order_name", "制单人"],
          ["create_time", "单据日期"],
          ["remark", "备注信息"],
        ];
      } else {
        tableData = [
          ["sn", "销售开卡单号"],
          ["close_sn", "关联销售关卡单号"],
          ["app_type_shop_type_flag", "应用类型"],
          ["oper_type", "开卡类型"],
          ["client_name", "客户名称"],
          ["card_num", "需开卡数量"],
          ["real_card_num", "已开卡数量"],
          ["close_card_status", "单据状态"],
          ["make_order_name", "制单人"],
          ["create_time", "单据日期"],
          ["remark", "备注信息"],
        ];
      }
      let tHeaders = tableData.map((item) => item[1]);
      let filterVals = tableData.map((item) => item[0]);
      let merges = [];
      let wscols = new Array(tHeaders.length).fill(15).map((n) => {
        return {
          wch: n,
        };
      });
      const API =
        API_SaleOrder[
        this.activeName == "saleClose"
          ? "getCloseCardList"
          : "getCloseOpenCardList"
        ];
      API({
        ...params,
      }).then((res) => {
        let list = changeDataList(res.data, "close_sell_trade_sn_list", [
          "related_sn",
        ]);

        list.forEach((item) => {
          item.app_type_shop_type_flag = this.getAppTypeName(item.app_type_shop_type_flag);
          item.create_time = Foundation.unixToDate(item.create_time);
          if (this.activeName === "saleClose") {
            item.oper_type = this.getCardCloseTypeName(item.oper_type);
            item.close_card_status = this.getCardCloseStatusTypeName(
              item.close_card_status
            );
          } else {
            item.oper_type = this.getCardOpenTypeName(item.oper_type);
            item.close_card_status = this.getCardOpenStatusTypeName(
              item.close_card_status
            );
          }
        });

        let data = list.map((item, index) => {
          if (item.span > 1) {
            let arr = new Array(tHeaders.length)
              .fill(0)
              .map((a, i) => {
                if ([2].includes(i)) return null;
                return {
                  s: { r: index + 1, c: i },
                  e: { r: index + item.span, c: i },
                };
              })
              .filter((a) => a);
            merges.push(...arr);
          }
          let obj = {};
          tHeaders.forEach((key, i) => (obj[key] = item[filterVals[i]]));
          return obj;
        });

        downloadExcel(
          {
            data,
            merges,
            wscols,
          },
          "销售关卡核销列表"
        );
        // handleDownload(list, tHeaders, filterVals, "销售关卡核销列表");
      });
    },
    // 获取详情
    getDetail (type, row, command) {
      const { id, sn } = row;
      this.$store.dispatch("addVisitedViews", this.$route);
      // command part 部分 all 整单
      // activeName saleClose 关卡 saleOpen 开卡

      let routeData = [
        {
          saleClose: {
            name: `financeClose${this.titleCase(command)}Detail`,
            query: {
              id,
              sn,
            },
          },
          saleOpen: {
            name: `financeOpen${this.titleCase(command)}Detail`,
            query: {
              id,
              sn,
            },
          },
        },
      ];
      this.$router.push(routeData[0][this.activeName]);
    },
    titleCase (str) {
      if (!str) return "";
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    },
    handleCloseCard (row) {
      const { sn, oper_type } = row;
      this.$confirm("确定要将进行关卡（禁用）操作吗", "关卡操作", {
        type: "warning",
      })
        .then(() => {
          API_SaleOrder.updateCardState({
            sn,
            oper_type,
          }).then((res) => {
            this.$message.success("关卡成功");
            this.getList();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleOpenCard (row) {
      const { sn, oper_type } = row;
      this.$confirm("确定要将进行开卡（激活）操作吗", "撤销操作", {
        type: "warning",
      })
        .then(() => {
          API_SaleOrder.updateCloseOpenState({
            sn,
            oper_type,
          }).then((res) => {
            this.$message.success("开卡成功");
            this.getList();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.col-auto {
  /deep/ .el-form-item__error {
    z-index: 8;
  }
}
</style>
